import './Payment.css';

const Payment = () => {
  return (
    <div className='Payment'>
      <h1>This will be the payment page</h1>
    </div>
  );
};

export default Payment;
